<template>
  <v-card
    :loading="loading"
    class="folder-item bg-grey-gradient"
    v-bind="$attrs"
    @click.prevent.stop="(e) => $emit('click', value, !inSelected)"
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
    @contextmenu.prevent.stop="(e) => $emit('contextmenu', e, value)"
  >
    <div :class="{ active }" class="item-active-indicator"></div>
    <div
      class="global-blur"
      :class="{
        'move-in-progress':
          moveInProgress && (value.u_type !== 'folders' || inSelected),
      }"
    ></div>

    <div
      class="folder-status"
      :class="{
        success: value.upwork_folder_id,
      }"
    >
      <span >{{ value.ext }}</span>
    </div>

    <v-list-item>
      <div class="checkbox" :class="{ expanded: allowSelection }">
        <v-slide-x-transition>
          <v-row v-if="allowSelection" class="selection-icon">
            <v-col>
              <v-checkbox
                :value="inSelected"
                color="primary"
                @click.stop.prevent="onCheckBox"
                v-model="checked"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-slide-x-transition>
      </div>

      <v-list-item-avatar tile :size="100">
        <v-row class="pa-0 ma-0 file-img-container">
          <v-col cols="12" class="pa-0">
            <div v-if="value.ext" class="theme-default">
              <div class="vue-file-agent grid-block-wrapper" style="padding: 0">
                <VueFilePreview
                  :value="value"
                  :linkable="true"
                  class="file-preview-wrapper grid-box-item grid-block"
                ></VueFilePreview>
              </div>
            </div>
            <v-icon :size="60" v-else class="folder-icon" dark>
              mdi-folder
            </v-icon>
          </v-col>
        </v-row>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          {{
            typeof value.name === "function" ? value.name() : value.name
          }}</v-list-item-title
        >

        <v-list-item-subtitle
          >{{ dateFormatter(value.created_at) }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-btn icon>
          <v-icon color="grey lighten-1">mdi-share</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <!-- <div class="card-actions-bottom">
      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="secondary" text @click.stop.prevent="onOpen">
          Open
        </v-btn>
      </v-card-actions>
    </div> -->
  </v-card>
</template>
  
<script>
import { FormatterHelper } from "../../../../../components/helpers/formatter.helper";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    allowSelection: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    moveInProgress: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    inSelected: function () {
      return this.selected && this.selected.length
        ? this.selected.find(
            (el) => el.id === this.value.id && el.u_type === this.value.u_type
          )
        : false;
    },
  },
  data() {
    return {
      durationFormatter: FormatterHelper.timeDuration,
      cutFormatter: FormatterHelper.cutString,
      dateFormatter: FormatterHelper.formatDate,

      loading: false,
      checked: false,
    };
  },
  components: {},

  methods: {
    onCheckBox() {
      this.$emit("onSelect", this.checked, this.value);
    },
    onOpen() {
      this.$emit("onOpen", this.value);
    },
  },
  watch: {
    selected: {
      handler() {
        this.checked = this.inSelected;
      },
      deep: true,
    },
  },
};
</script>
  
  
<style lang="scss" scoped>
.folder-item {
  z-index: 2;
  overflow: hidden;

  .global-blur {
    z-index: -2;
    position: absolute;
    transition: all 0.3s;
    opacity: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.7);
    &.move-in-progress {
      z-index: 9999;
      opacity: 1;
    }
  }

  .checkbox {
    width: 0px;
    transition: all 0.3s;
    &.expanded {
      width: 40px;
    }
  }

  .folder-title {
    text-transform: uppercase;
    display: inline-block;
    padding-right: 60px;
  }

  .folder-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    top: 0;
    text-transform: uppercase;
    /* overflow: hidden; */
    /* margin: 0 auto; */
    text-align: center;
    top: 22px;
  }

  .file-preview-wrapper {
    height: 100%;
    width: 100%;
  }

  .file-preview-img {
    width: 100%;
    height: 100%;
  }

  .theme-default {
    height: 100%;
    width: 100%;
  }

  .grid-block-wrapper .grid-block {
    min-width: 80px;
    width: 80px;
  }

  .folder-icon {
    min-width: 80px;
    min-height: 80px;
    width: 100px;
  }

  .selection-icon {
    max-width: 40px;
  }
}
</style>