var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AView',{attrs:{"value":_vm.value,"name":_vm.id ? _vm.folder.name : 'Home',"hasFilters":!!(_vm.filter.search || _vm.filter.type),"active":_vm.id === _vm.activeFolderId},on:{"onHover":() => _vm.onHover(_vm.folder),"onActivate":(e) => _vm.$emit('onActivate', e),"onMaximize":(e) => _vm.$emit('onMaximize', e),"onClose":(e) => _vm.$emit('onClose', e),"onExpand":(e) => _vm.$emit('onExpand', e),"onCollapse":(e) => _vm.$emit('onCollapse', e),"onFolderBulkSelect":_vm.onFolderBulkSelect,"contextmenu":_vm.show},scopedSlots:_vm._u([{key:"content",fn:function({}){return [_c('v-fade-transition',[(_vm.showDrag)?_c('div',{staticClass:"drop-zone",on:{"dragleave":_vm.onDragLeave,"dragenter":function($event){_vm.showDrag = true},"drop":function($event){return _vm.handleFileDrop($event)},"dragover":function($event){$event.preventDefault();}}},[(!_vm.loading)?_c('v-icon',{staticClass:"mb-2",attrs:{"color":"white","size":60}},[_vm._v("mdi-file-document-plus-outline")]):_vm._e(),(!_vm.loading)?_c('span',[_vm._v("Drag File Here")]):_vm._e(),(_vm.loading)?_c('v-progress-circular',{staticClass:"mb-2",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.loading)?_c('span',[_vm._v("Uploading...")]):_vm._e()],1):_vm._e()]),_c('div',{on:{"dragenter":function($event){_vm.showDrag = true},"drop":function($event){return _vm.handleFileDrop($event)},"dragover":function($event){$event.preventDefault();}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('FormSection',{attrs:{"right":"","label":_vm.id ? _vm.folder.name : 'Home',"icon":_vm.value.view.display.icon,"actions":_vm.actions,"editable":!!_vm.actions.length,"payload":{
              from: _vm.value,
            }},on:{"onFolderBulkSelect":_vm.onFolderBulkSelect}})],1)],1),_c('v-row',{staticClass:"scroll-row d-flex flex-grow-1"},[_c('v-col',{staticClass:"pa-0 ma-0 d-flex flex-column flex-grow-1",attrs:{"cols":"12"}},[_c('v-virtual-scroll',{staticClass:"pa-0 ma-0",attrs:{"bench":5,"items":_vm.items,"height":"630","item-height":"120"},on:{"scroll":_vm.onScroll},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('FolderItemVue',{key:item.id,staticClass:"ma-2",style:({
                  height: '110px',
                }),attrs:{"active":item.id === _vm.activeFolderId,"allowSelection":_vm.allowBulkOperations,"moveInProgress":_vm.itemsMoveInProgress,"selected":_vm.selected,"value":item},on:{"contextmenu":_vm.show,"onOpen":_vm.onOpen,"click":_vm.onOpen,"mouseover":_vm.onHover,"mouseleave":() => {},"onSelect":_vm.onSelect}})]}}],null,true)})],1)],1),_c('Pagination',{attrs:{"value":_vm.pagination,"show":_vm.showMore,"total":_vm.items.length},on:{"onLoadMore":_vm.nextPage}})],1),(_vm.useMenu())?_c('v-menu',{attrs:{"value":_vm.showMenu,"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":"","close-on-content-click":_vm.itemsMoveInProgress ? false : true,"content-class":_vm.itemsMoveInProgress ? 'display-none' : ''},on:{"input":_vm.onCloseMenu}},[_c('v-list',{attrs:{"dark":""}},[_c('v-list-item',{attrs:{"link":"","disabled":!(_vm.selected && _vm.selected.length)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$store.dispatch('FolderStore/moveItems', {
              destination: { id: 'home' },
            })}}},[_c('v-list-item-title',[_vm._v("Move "+_vm._s(_vm.selected && _vm.selected.length ? `(${_vm.selected.length})` : "")+" to Home")]),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-content-save-move-outline")])],1)],1),_c('v-list-item',{attrs:{"link":"","disabled":!(_vm.selected && _vm.selected.length)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$store.dispatch('FolderStore/initMoveItems')}}},[_c('v-list-item-title',[_vm._v("Move "+_vm._s(_vm.selected && _vm.selected.length ? `(${_vm.selected.length})` : "")+" to...")]),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-content-save-move-outline")])],1)],1),_c('v-list-item',{attrs:{"link":"","disabled":!(_vm.selected && _vm.selected.length)},on:{"click":function($event){return _vm.$store.dispatch('FolderStore/removeItems')}}},[_c('v-list-item-title',[_vm._v("Remove "+_vm._s(_vm.selected && _vm.selected.length ? `(${_vm.selected.length})` : ""))]),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)],1)],1)],1):_vm._e()]}},{key:"actions",fn:function({}){return [_c('FolderFilters',{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }