<template>
  <AView
    :value="value"
    :name="id ? folder.name : 'Home'"
    :hasFilters="!!(filter.search || filter.type)"
    :active="id === activeFolderId"
    @onHover="() => onHover(folder)"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onFolderBulkSelect="onFolderBulkSelect"
    @contextmenu="show"
  >
    <template v-slot:content="{}">
      <v-fade-transition>
        <div
          v-if="showDrag"
          class="drop-zone"
          @dragleave="onDragLeave"
          @dragenter="showDrag = true"
          @drop="handleFileDrop($event)"
          @dragover.prevent
        >
          <v-icon v-if="!loading" color="white" :size="60" class="mb-2"
            >mdi-file-document-plus-outline</v-icon
          >
          <span v-if="!loading">Drag File Here</span>

          <v-progress-circular
            indeterminate
            color="primary"
            class="mb-2"
            v-if="loading"
          ></v-progress-circular>
          <span v-if="loading">Uploading...</span>
        </div>
      </v-fade-transition>

      <div
        @dragenter="showDrag = true"
        @drop="handleFileDrop($event)"
        @dragover.prevent
      >
        <v-row>
          <v-col cols="12">
            <FormSection
              right
              :label="id ? folder.name : 'Home'"
              :icon="value.view.display.icon"
              :actions="actions"
              :editable="!!actions.length"
              :payload="{
                from: value,
              }"
              @onFolderBulkSelect="onFolderBulkSelect"
            ></FormSection>
          </v-col>
        </v-row>
        <v-row class="scroll-row d-flex flex-grow-1">
          <v-col cols="12" class="pa-0 ma-0 d-flex flex-column flex-grow-1">
            <v-virtual-scroll
              class="pa-0 ma-0"
              :bench="5"
              :items="items"
              height="630"
              item-height="120"
              @scroll="onScroll"
            >
              <template v-slot:default="{ item }">
                <FolderItemVue
                  :key="item.id"
                  :active="item.id === activeFolderId"
                  :allowSelection="allowBulkOperations"
                  :moveInProgress="itemsMoveInProgress"
                  :selected="selected"
                  @contextmenu="show"
                  @onOpen="onOpen"
                  @click="onOpen"
                  @mouseover="onHover"
                  @mouseleave="() => {}"
                  :value="item"
                  :style="{
                    height: '110px',
                  }"
                  class="ma-2"
                  @onSelect="onSelect"
                ></FolderItemVue>
              </template>
            </v-virtual-scroll>

            <!-- <draggable
            v-model="items"
            group="files"
            :animation="1"
            :move="onMove"
            @start="isDragging = true"
            @end="isDragging = false"
            draggable="li"
          >
            <transition-group
              class="lead-container"
              type="transition"
              name="y-list"
              tag="ul"
            >
              <li class="sales-item ma-2" v-for="(item, i) in items" :key="i">
                <FolderItemVue
                  :active="item.id === activeFolderId"
                  @onOpen="onSelect"
                  @click="onSelect"
                  @mouseover="onHover"
                  @mouseleave="() => {}"
                  :value="item"
                  class="ma-2"
                ></FolderItemVue>
              </li>
            </transition-group>
          </draggable> -->
          </v-col>
        </v-row>

        <Pagination
          :value="pagination"
          :show="showMore"
          :total="items.length"
          @onLoadMore="nextPage"
        ></Pagination>
      </div>

      <v-menu
        :value="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        :close-on-content-click="itemsMoveInProgress ? false : true"
        @input="onCloseMenu"
        :content-class="itemsMoveInProgress ? 'display-none' : ''"
        v-if="useMenu()"
      >
        <v-list dark>
          <v-list-item
            link
            :disabled="!(selected && selected.length)"
            @click.stop.prevent="
              $store.dispatch('FolderStore/moveItems', {
                destination: { id: 'home' },
              })
            "
          >
            <v-list-item-title
              >Move
              {{ selected && selected.length ? `(${selected.length})` : "" }}
              to Home</v-list-item-title
            >
            <v-list-item-action>
              <v-icon>mdi-content-save-move-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            link
            :disabled="!(selected && selected.length)"
            @click.stop.prevent="$store.dispatch('FolderStore/initMoveItems')"
          >
            <v-list-item-title
              >Move
              {{ selected && selected.length ? `(${selected.length})` : "" }}
              to...</v-list-item-title
            >
            <v-list-item-action>
              <v-icon>mdi-content-save-move-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            link
            :disabled="!(selected && selected.length)"
            @click="$store.dispatch('FolderStore/removeItems')"
          >
            <v-list-item-title
              >Remove
              {{
                selected && selected.length ? `(${selected.length})` : ""
              }}</v-list-item-title
            >
            <v-list-item-action>
              <v-icon color="error">mdi-delete</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template v-slot:actions="{}">
      <FolderFilters v-model="filter"> </FolderFilters>
    </template>
  </AView>
</template>
      
      
      
<script>
// import draggable from "vuedraggable";
import { mapState } from "vuex";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";
import FolderItemVue from "../components/FolderItem.vue";
import FormSection from "../../../../../components/wad/atoms/common/FormSection.vue";
import FolderFilters from "../components/FolderFilters.vue";
import Pagination from "../../../../../components/wad/atoms/common/Pagination.vue";
import { FOLDER_BUTTONS } from "../constants/actions.gh";
import { PermissionsHelper } from "../../../../../components/helpers/permissions.helper";

export default {
  name: "FolderList",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    // draggable,
    AView,
    FormSection,
    FolderItemVue,
    FolderFilters,
    Pagination,
  },
  computed: {
    ...mapState("FolderStore", [
      "activeFolderId",
      "folders",
      "displayedFolders",
      "allowBulkOperations",
      "itemsMoveInProgress",
      "selected",
      "showMenu",
      "x",
      "y",
    ]),
    ...mapState("FileStore", ["loading"]),
    actions: function () {
      return PermissionsHelper.getActions(
        [
          FOLDER_BUTTONS.CREATE_NEW,
          FOLDER_BUTTONS.BULK_SELECT,
          FOLDER_BUTTONS.HOME,
        ],
        this.value.permissions
      );
    },
  },
  mounted() {
    this.$store.dispatch("FolderStore/GetFolder", {
      id: this.id,
      refresh: true,
    });

    this.folder =
      this.folders[this.id] && this.folders[this.id].folder
        ? this.folders[this.id].folder
        : {
            name: "",
          };
    this.items =
      this.folders[this.id] &&
      this.folders[this.id].content &&
      this.folders[this.id].content.items
        ? this.folders[this.id].content.items
        : [];
    this.pagination =
      this.folders[this.id] &&
      this.folders[this.id].content &&
      this.folders[this.id].content.pagination
        ? this.folders[this.id].content.pagination
        : {};

    this.watcher = this.$watch(
      `folders.${this.id}`,
      (newVal) => {
        if (!newVal) return;

        this.folder = newVal.folder ? newVal.folder : {};
        this.items = newVal.content.items ? newVal.content.items : [];
        this.pagination =
          newVal.content && newVal.content.pagination
            ? newVal.content.pagination
            : {};
      },
      {
        deep: true,
      }
    );
  },
  beforeDestroy() {
    if (this.watcher) this.watcher();
  },
  data() {
    const id =
      this.value.relation &&
      this.value.relation.params &&
      this.value.relation.params.parentId
        ? this.value.relation.params.parentId
        : "home";

    return {
      id,
      filter: {},
      showMore: false,
      folder: {},
      items: [],
      pagination: {},
      watcher: undefined,
      test: undefined,
      showDrag: false,
    };
  },
  methods: {
    onDragLeave() {
      console.log("WHY???");
      this.showDrag = false;
    },
    async handleFileDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];

      console.log("file: ", file);

      await this.$store.dispatch("FileStore/uploadFile", {
        folderId: this.folder.id,
        newFile: file,
      });

      this.showDrag = false;
    },
    onOutside() {
      this.$store.dispatch("FolderStore/closeMenu");
    },
    onCloseMenu() {
      this.$store.dispatch("FolderStore/closeMenu");
    },
    useMenu() {
      if (this.folders && this.folders[this.id]) {
        const lastId = Object.keys(this.folders).pop();
        const lastFolder = this.folders[lastId];

        if (lastFolder.folder.id === this.id) return true;
        else false;
      } else return false;
    },
    show(e, entity) {
      e.preventDefault();
      this.$store.commit("FolderStore/setMenu", {
        event: e,
        candidate: entity,
      });
    },
    onHover(value) {
      this.$store.commit("FolderStore/setActiveId", value.id);
    },
    onOpen(folder, moveAllowed) {
      if (this.itemsMoveInProgress && moveAllowed) {
        this.$store.dispatch("FolderStore/moveItems", {
          destination: folder,
        });
      } else if (this.itemsMoveInProgress && !moveAllowed) {
        console.log("Move Not allowed");
      } else if (folder.u_type === "folders")
        this.$store.dispatch("FolderStore/openFolderDetails", {
          from: this.value,
          folder,
        });
      else
        this.$store.dispatch("FileStore/openFileDetails", {
          from: this.value,
          file: folder,
        });
    },
    onFolderBulkSelect() {
      this.$store.commit("FolderStore/setAllowBulkOperations");
    },
    onSelect(value, item) {
      this.$store.commit("FolderStore/setSelected", { value, item });
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.showMore = true;
      } else {
        this.showMore = false;
      }
    },
    nextPage() {
      this.pagination.page++;
      console.log("PAGE CHANGED");
      this.$store.dispatch("FolderStore/GetFolder", {
        id: this.id,
        pagination: this.pagination,
        filter: this.filter,
        refresh: true,
      });
    },
    onMove(props) {
      const draggedElement = props.draggedContext.element;

      console.log("draggedElement: ", draggedElement);
    },
  },
  watch: {
    filter: {
      handler(newVal) {
        this.$store.dispatch("FolderStore/GetFolder", {
          id: this.id,
          filter: newVal,
          reset: true,
        });
      },
      deep: true,
    },
  },
};
</script>
      
      
      
<style scoped lang="scss" >
.scroll-row {
  height: calc(100% - 110px);
}

.drop-zone {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    font-size: 24px;
    color: white;
  }
}
</style>