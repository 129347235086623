import {
    store
} from "../../../../../main"
import {
    FOLDER_PERMISSIONS
} from "./permissions.gh"


export const FOLDER_BUTTONS = {
    LIST: {
        name: "Folders",
        description: "Will open Parent Folder",
        icon: "mdi-folder-table",
        color: "#23842a",
        on: "onFolder",
        permission: FOLDER_PERMISSIONS.ALLOW_USE_FOLDER,
        handler: (props) => store.dispatch('FolderStore/openFolder', props)
    },
    CREATE_NEW: {
        name: "Create",
        description: "Create a new Folder",
        icon: "mdi-plus",
        color: "#23842a",
        on: "onFolderCreate",
        permission: FOLDER_PERMISSIONS.ALLOW_CREATE_NEW_FOLDER,
        handler: (props) => store.dispatch('FolderStore/openFolderCreation', props)
    },
    HOME: {
        name: "Home",
        description: "Open Home Directory",
        icon: "mdi-alpha-h-box-outline",
        color: "#23842a",
        on: "onFolderHome",
        permission: FOLDER_PERMISSIONS.ALLOW_USE_FOLDER,
        handler: (props) => store.dispatch('FolderStore/openFolderDetails', {
            ...props,
            folder: {
                id: 'home'
            }
        })
    },
    BULK_SELECT: {
        name: "Bulk Select",
        description: "Start Bulk Operation",
        icon: "mdi-format-list-checks",
        color: "#23842a",
        on: "onFolderBulkSelect",
        permission: FOLDER_PERMISSIONS.ALLOW_USE_FOLDER,
    },
    SAVE: {
        name: "Save",
        description: "Save New Folder",
        icon: "mdi-check",
        color: "#23842a",
        on: "onFolderSave",
        permission: FOLDER_PERMISSIONS.ALLOW_CREATE_NEW_FOLDER,
    }
}